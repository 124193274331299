/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    '1boHVo2HEEJyKZjct7Babv.webp': { uri: '/images/1boHVo2HEEJyKZjct7Babv.webp' },
'bZV1ym2HQur2F8XC4vEM9g.png': { uri: '/images/bZV1ym2HQur2F8XC4vEM9g.png' },
'v5uhy1jDBmWy9uLo9xZP4e.png': { uri: '/images/v5uhy1jDBmWy9uLo9xZP4e.png' },
'wZMPb6TJzYF5yV4dS4moWB.png': { uri: '/images/wZMPb6TJzYF5yV4dS4moWB.png' },
'69pYaMedNGHWQFqduQo6wp.png': { uri: '/images/69pYaMedNGHWQFqduQo6wp.png' },
'goAKodBQn3xZUfpL1NZpmw.png': { uri: '/images/goAKodBQn3xZUfpL1NZpmw.png' },
'55JvtfWEq8UhmyG2yvNjSR.png': { uri: '/images/55JvtfWEq8UhmyG2yvNjSR.png' },
'8fzrw55gLXtt2H3TCPaqf8.png': { uri: '/images/8fzrw55gLXtt2H3TCPaqf8.png' },
'2WpesRNMokaGv1Skhomybg.png': { uri: '/images/2WpesRNMokaGv1Skhomybg.png' },
'5GKzgBGuM8GZNVsVAnbcKY.png': { uri: '/images/5GKzgBGuM8GZNVsVAnbcKY.png' },
'ufJeF2Snn85SjCMRHgmYBX.jpg': { uri: '/images/ufJeF2Snn85SjCMRHgmYBX.jpg' },
'vjULddDxeTuthrWE4uTf4i.jpg': { uri: '/images/vjULddDxeTuthrWE4uTf4i.jpg' }
}

export default imageStaticSourcesByFileName
